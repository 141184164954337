@import '../abstracts/variables';

.modal-preview {
  max-width: unset;
  margin: 0;

  .modal-content {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
}

.modal-document-share {
  box-shadow: unset;

  .modal-content {
    background-color: transparent;

    .c-modal {
      padding: 1.5rem;
      background: white;
      box-shadow: 0 10px 25px 0px rgb(0 0 0 / 16%);
      border-radius: 0.3rem;
    }

    .app-document-link {
      .document-link-wrapper {
        padding: 0 !important;
      }
    }
  }
}

// MODAl
.modal, .cdk-dialog-container {
  .modal-dialog {
    box-shadow: 0 10px 25px 0px rgb(0 0 0 / 16%);
  }

  .modal-content {
    border: 0;
  }

  .dialog-container {
    width: auto;
    height: auto;
    .dialog-header {
      padding: 1.5rem;
    }
    .dialog-inner-content {
      max-height: calc(100vh - 220px);
      overflow: auto;

      .content-container {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
      }
    }
    .auto-height {
      height: 100% !important;
    }
    .dialog-footer {
      padding: 1.5rem;
    }
  }
}

.modal-search-system {
  border: solid 0.8px $form-border-color;
  border-radius: 7px;
  .modal-content {
    border-radius: 7px;
  }
}

.modal-backdrop {
  background-color: #172b4d;
}

.c-modal {
  padding: 1.5rem;
}

.c-modal-header {
  font-size: 1.25rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid $form-border-color;
}


@media (min-width: 576px) {
  .modal-dialog {
    @apply max-w-full w-[600px];
  }

  // Modal based on type
  .modal-sm {
    @apply max-w-full w-[450px] #{!important};
  }

  .modal-md {
    @apply max-w-full w-[600px] #{!important};
  }

  .modal-lg {
    @apply max-w-full w-[750px] #{!important};
  }

  .modal-xl {
    @apply max-w-full w-[900px] #{!important};
  }

  // Modal based on width
  .modal-700px {
    @apply max-w-full w-[700px] #{!important};
  }

  .modal-800px {
    @apply max-w-full w-[800px] #{!important};
  }

  .modal-900px {
    @apply max-w-full w-[1000px] #{!important};
  }

  .modal-1000px {
    @apply max-w-full w-[1000px] #{!important};
  }

  .modal-1200px {
    @apply max-w-full w-[1200px] #{!important};
  }

  // Modal based on percent
  .modal-xl-90 {
    @apply max-w-full w-[90%] #{!important};
  }

  // Modal custom
  .modal-auto-fit-1000px {
    @apply max-w-[1000px] w-fit min-w-[600px] #{!important};
  }

  .modal-preview {
    @apply w-screen h-screen mt-0 #{!important};

    .cdk-dialog-container {
      max-height: unset !important;
    }
  }

  .modal-task-detail,
  .x-modal-large {
    width: 90%;
    max-width: 1280px;
  }
}

@media (min-width: 992px) {
  .modal-1000px {
    max-width: 1000px;
  }

  .modal-1200px {
    max-width: 1200px;
  }
}


// CDK DIALOG MODAL
cdk-dialog-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: white;
  background-clip: padding-box;
  border: none;
  border-radius: 0.3rem;
  outline: 0;
  max-width: inherit;
  max-height: calc(100vh - 3rem)!important;
  overflow-y: auto;

  &:not(:hover) {
    scrollbar-color: var(--x-btn-hover) white !important;
  }

  @media (width <= 576px) {
    width: 100vw!important;
    margin: 0.5rem;
  }
}

@media (width <= 576px) {
  .cdk-overlay-pane:has(.cdk-dialog-container) {
    margin: 0!important;
  }
}

.mat-mdc-dialog-container {
  .sm-modal {
    @apply max-w-full w-[450px] #{!important};
  }

  .md-modal {
    @apply max-w-full w-[600px] #{!important};
  }

  .lg-modal {
    @apply max-w-full w-[750px] #{!important};
  }

  .xl-modal {
    @apply max-w-full w-[900px] #{!important};
  }
}
